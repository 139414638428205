<template>
  <v-container>
    <h3 class="text-center">Browse Profiles for Import Files</h3>
    <v-data-table
      :headers="headers"
      :items="listOfProfiles"
      item-key="type"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-spacer />
          <v-btn
            class="mx-2"
            fab
            dark
            color="indigo"
            @click="newProfile"
          >
            <v-icon dark>
              {{icons.mdiPlus}}
            </v-icon>
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small class="mr-2"
          @click="editProfile(item)"
        >
          {{icons.mdiPencil}}
        </v-icon>
        <v-icon
          small class="mr-2"
          @click="deleteProfile(item)"
        >
          {{icons.mdiDelete}}
        </v-icon>
      </template>

      <template v-slot:[`item.type`]="{ item, value }">
      <td class="text-start clickable" @click="viewProfile(item)">{{value}}</td>
    </template>
    </v-data-table>

  </v-container>
</template>

<script>
import {
  mdiPlus,
  mdiDelete,
  mdiPencil,
} from '@mdi/js';

const initdata = () => ({
  icons: {
    mdiPlus,
    mdiDelete,
    mdiPencil,
  },
  canDelete: true,
  canAdd: true,
  importprofiles: [
  ],
  headers: [
    { text: 'Profiles', value: 'type' },
    { text: 'Action', value: 'actions', sortable: false },
  ],
});

export default {
  name: 'BrowseImportProfiles',
  data: () => initdata(),
  components: {
  },
  computed: {
    listOfProfiles() {
      return this.$store.state.Bulk.listOfProfiles;
    },
  },
  methods: {
    editProfile(item) {
      this.$router.push({ name: 'Configure an Import Profile', params: { id: item.id } });
    },
    newProfile() {
      this.$router.push({ name: 'Configure an Import Profile' });
    },
    viewProfile(item) {
      this.$router.push({ name: 'View Import Files Profile', params: { id: item.id } });
    },
    deleteProfile(item) {
      if (window.confirm('Are you sure want to delete this profile?')) {
        this.$store.dispatch('Bulk/delImportFileProfile', item);
      }
    },
  },
  created() {
    this.$store.dispatch('Bulk/loadProfiles');
  },
};
</script>
